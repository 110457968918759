import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19234324"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = ["cx", "cy", "r", "stroke-width", "stroke-dasharray"];
const _hoisted_3 = {
  id: "clipArc_small"
};
const _hoisted_4 = ["transform", "d"];
const _hoisted_5 = ["transform", "d"];
const _hoisted_6 = ["gradientTransform"];
const _hoisted_7 = ["stop-color"];
import { computed } from "@vue/reactivity";
export default {
  __name: 'CircleChartSmall',
  props: {
    score: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const risk = computed(() => props.score > 7 ? 'low' : props.score > 4.9 ? 'middle' : 'high');
    const isMobile = computed(() => props.size === 'small');
    const rotate = computed(() => props.score / 10 * 212);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["circle-chart relative", {
          'small': _unref(isMobile)
        }])
      }, [(_openBlock(), _createElementBlock("svg", {
        width: _unref(isMobile) ? 235 : 470,
        height: _unref(isMobile) ? 150 : 300,
        viewBox: _unref(isMobile) ? '0 0 235 150' : '0 0 470 300',
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [_createElementVNode("g", null, [_createElementVNode("circle", {
        cx: _unref(isMobile) ? 117.5 : 235,
        cy: _unref(isMobile) ? 117.5 : 235,
        r: _unref(isMobile) ? 111 : 222,
        stroke: "url(#paint0_angular_12_458_small)",
        "stroke-width": _unref(isMobile) ? 13 : 26,
        "stroke-dasharray": _unref(isMobile) ? '3 2' : '6 4',
        "clip-path": "url(#clipArc_small)"
      }, null, 8, _hoisted_2)]), _createElementVNode("defs", null, [_createElementVNode("clipPath", _hoisted_3, [_unref(rotate) < 135 ? (_openBlock(), _createElementBlock("path", {
        key: 0,
        transform: _unref(isMobile) ? `rotate(${_unref(rotate)}, 117.5, 117.5)` : `rotate(${_unref(rotate)}, 235, 235)`,
        d: _unref(isMobile) ? 'M 117.5,117.5 L 0,150 A 111,111 0 0 0 220,125 Z' : 'M 235,235 L 0,300 A 222,222 0 0 0 440,350 Z'
      }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("path", {
        key: 1,
        transform: _unref(isMobile) ? `rotate(${_unref(rotate) - 212}, 117.5, 117.5)` : `rotate(${_unref(rotate) - 212}, 235, 235)`,
        d: _unref(isMobile) ? 'M 117.5,117.5 L 0,150 A 5,130 0 0 1 235,150 Z' : 'M 235,235 L 0,300 A 10,260 0 0 1 470,300 Z'
      }, null, 8, _hoisted_5))])]), _createElementVNode("defs", null, [_createElementVNode("radialGradient", {
        id: "paint0_angular_12_458_small",
        cx: "0",
        cy: "0",
        r: "1",
        gradientUnits: "userSpaceOnUse",
        gradientTransform: _unref(isMobile) ? 'translate(117.5 117.5) rotate(-90) scale(117.5)' : 'translate(235 235) rotate(-90) scale(235)'
      }, [_createElementVNode("stop", {
        offset: "0.698448",
        "stop-color": _unref(risk) === 'low' ? '#59AB6C' : _unref(risk) === 'middle' ? '#F16514' : '#EB4747'
      }, null, 8, _hoisted_7)], 8, _hoisted_6)])], 8, _hoisted_1)), _createElementVNode("div", {
        class: _normalizeClass(["content text-center absolute bottom-7 left-[50%] transform translate-x-[-50%]", {
          'bottom-5px': _unref(isMobile)
        }])
      }, [_createElementVNode("span", {
        class: _normalizeClass(["text-2xl", {
          'text-[14px]': _unref(isMobile)
        }])
      }, [_createElementVNode("span", {
        class: _normalizeClass(["text-7xl font-bold", [_unref(risk) === 'low' ? 'text-[#59AB6C]' : _unref(risk) === 'middle' ? 'text-[#F16514]' : 'text-[#EB4747]', _unref(isMobile) && 'text-[44px]']])
      }, _toDisplayString(__props.score), 3), _createTextVNode("/10分")], 2), _createElementVNode("p", {
        class: _normalizeClass(["text-2xl font-medium mt-4", {
          'text-[14px] mt-[15px]': _unref(isMobile)
        }])
      }, _toDisplayString(_unref(risk) === 'low' ? '低风险' : _unref(risk) === 'middle' ? '中风险' : '高风险'), 3)], 2)], 2);
    };
  }
};