import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65584ef2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "font-medium text-sm mr-[10px]"
};
const _hoisted_2 = {
  class: "ml-[6px]"
};
const _hoisted_3 = {
  class: "text-[10px]"
};
const _hoisted_4 = {
  class: "font-bold text-sm"
};
import { computed } from "@vue/reactivity";
export default {
  __name: 'RateGroup',
  props: {
    score: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const props = __props;
    const risk = computed(() => props.score > 7 ? 'low' : props.score > 4.9 ? 'middle' : 'high');
    const blocks = computed(() => {
      const blockValue = 2;
      return Array.from({
        length: 5
      }, (_, i) => {
        const threshold = (i + 1) * blockValue;
        if (props.score >= threshold) {
          return 1; // 完全
        } else if (props.score > threshold - blockValue && props.score < threshold) {
          return (props.score - i * blockValue) / 2; // 半
        } else {
          return 0; // 灰色
        }
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex items-center", [_unref(risk)]])
      }, [_createElementVNode("span", _hoisted_1, _toDisplayString(_unref(risk) === 'low' ? '低风险' : _unref(risk) === 'middle' ? '中风险' : '高风险'), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blocks), (block, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "bg-[#EBEBEB] w-[22px] h-[8px] mr-[4px] rounded-[4px] overflow-hidden",
          key: index
        }, [_createElementVNode("div", {
          class: "h-full rate-process",
          style: _normalizeStyle(`width: ${block * 100}%`)
        }, null, 4)]);
      }), 128)), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(__props.score), 1), _createTextVNode("/10分")])])], 2);
    };
  }
};