import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/copy.svg';
const _hoisted_1 = {
  class: "text-sm mt-4 max-h-91 overflow-y-scroll counters-container <lg:overflow-x-scroll <lg:max-h-150"
};
const _hoisted_2 = {
  class: "flex items-center"
};
const _hoisted_3 = {
  class: "font-medium text-[#583BFF] mr-5px"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "mt-4 flex justify-center"
};
import { ElMessage } from 'element-plus';
import 'element-plus/theme-chalk/el-message.css';
import { copy, dealAddress, dealAmount } from '@/utils';
import { ref, watch } from "vue";
import { Search } from '@element-plus/icons-vue';
export default {
  __name: 'AddressCounters',
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    counter: {
      type: String,
      default: ''
    },
    symbol: {
      type: String,
      default: 'USDT'
    },
    total: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:counter', 'update:page'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const localCounter = ref(props.counter);
    watch(() => props.counter, newVal => {
      localCounter.value = newVal;
    });

    // 定义一个防抖定时器
    let debounceTimer = null;
    watch(() => localCounter.value, newVal => {
      // 清除之前的定时器
      if (debounceTimer) clearTimeout(debounceTimer);

      // 设置新的定时器
      debounceTimer = setTimeout(() => {
        emit('update:counter', newVal);
      }, 1000); // 1秒后触发 handleInput 方法
    });
    const toCopy = val => {
      copy(val);
      ElMessage.success('复制成功');
    };
    const handleCurrentChange = val => {
      emit('update:page', val);
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: localCounter.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => localCounter.value = $event),
        style: {
          "width": "240px"
        },
        placeholder: "搜索地址",
        "prefix-icon": _unref(Search),
        class: "counter-address"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_table, {
        data: __props.dataList,
        class: "w-full mt-4 <lg:min-w-[480px]"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "direction",
          label: "方向",
          width: "70"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", {
            class: _normalizeClass([scope.row.direction === 1 ? 'text-[#59AB6C]' : 'text-[#EB4747]'])
          }, _toDisplayString(scope.row.direction === 1 ? '转入' : '转出'), 3)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "counter",
          label: "交易对手"
        }, {
          default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_unref(dealAddress)(scope.row.counter, 8)), 1), _createElementVNode("img", {
            src: _imports_0,
            class: "w-4 h-4 cursor-pointer",
            alt: "",
            onClick: $event => toCopy(scope.row.counter)
          }, null, 8, _hoisted_4)])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "txCount",
          label: "交易次数",
          align: "right",
          width: "100"
        }), _createVNode(_component_el_table_column, {
          prop: "name",
          label: `${__props.symbol}交易数量`,
          align: "right"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_unref(dealAmount)(scope.row.txValue)), 1)]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["data"]), _withDirectives(_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        class: "counter-pagination",
        layout: "prev, pager, next",
        total: __props.total,
        onCurrentChange: handleCurrentChange,
        background: ""
      }, null, 8, ["total"])], 512), [[_vShow, __props.total > 10]])]);
    };
  }
};