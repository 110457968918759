/**
 * 复制文本到clipboard
 * @param {*target} 目标文字
 */
export const copy = (target) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(target);
  } else {
    let textArea = document.createElement('textarea')
    textArea.value = target
    textArea.style.position = 'absolute'
    textArea.style.opacity = '0'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    })
  }
}

export const dealAddress = (str, start=14) => {
  if (!str) return '';
  if (str.length <= 18) {
    return str;
  }
  const firstPart = str.substring(0, start);
  const lastPart = str.substring(str.length - 4);
  return `${firstPart}...${lastPart}`;
}

export const dealAmount = (num) => {
  if (!num) return 0
  // 转换数字为字符串，检查小数部分的长度
  const numStr = num.toString();
  const decimalIndex = numStr.indexOf('.');

  if (decimalIndex !== -1) {
    // 获取小数部分的长度
    const decimalLength = numStr.length - decimalIndex - 1;

    // 如果小数部分超过4位，进行四舍五入保留4位小数
    if (decimalLength > 4) {
      return parseFloat(num.toFixed(4));
    }
  }

  // 小数部分不超过4位，返回原始数字
  return num;
}

/**
 * 时间戳转换
 * @param {*timestamp} 时间戳
 * 1619519162000 => 2021/04/27 18:26:02
 */
export const formatTimestamp = (timestamp) => {
  if (!timestamp) return ''
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
  const day = String(date.getDate()).padStart(2, '0');
  
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}
